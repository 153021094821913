import React from 'react'
import { IImageBannerProps } from './types'
import WithAnchor from '@/components/base/with-anchor'
import Image from '@/components/base/image'
import useDeviceType from '@/hook/useDeviceType'

const ImageBanner: React.FC<IImageBannerProps> = ({
  image,
  mobileImage,
  link,
  marginTop = 0,
  marginBottom = 0,
}) => {
  const deviceType = useDeviceType()

  if (!image?.src || !mobileImage?.src) {
    return null
  }

  const marginXResponsiveCalculate = (value: number): number => {
    switch (deviceType) {
      case 'desktop':
        return value

      case 'laptop':
        return value * 0.85

      case 'tablet':
        return value * 0.65

      case 'mobile':
        return value * 0.5

      default:
        return 0
    }
  }

  return (
    <WithAnchor
      className="image-banner"
      link={link}
      style={{
        marginTop: `${marginXResponsiveCalculate(marginTop)}px`,
        marginBottom: `${marginXResponsiveCalculate(marginBottom)}px`,
      }}
    >
      <Image
        {...(deviceType === 'mobile' ? mobileImage : image)}
        alt="banner-image"
      />
    </WithAnchor>
  )
}

export default ImageBanner
