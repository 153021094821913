import React from 'react'
import { IWithAnchorProps } from './types'
import Anchor from '@/components/base/anchor'

const WithAnchor: React.FC<IWithAnchorProps> = ({
  children,
  link,
  className,
  style = {},
}) => {
  if (link?.href) {
    return (
      <Anchor className={className} {...link} style={style}>
        {children}
      </Anchor>
    )
  }

  if (className) {
    return (
      <div className={className} style={style}>
        {children}
      </div>
    )
  }

  return children
}

export default WithAnchor
